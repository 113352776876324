import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class License extends Resource {
  bill_number?: string | null;
  order_number?: string | null;
  inventory_number?: string | null;
  licensor!: string;
  licenseType!: string;
  company!: Employee.Company;
  licenseKey!: string;
  purchase_date?: string | null;
  purchase_price?: string | null;
  paymentCycle?: License.paymentCycle | null;
  contractDuration?: string | null;
  status!: boolean;
  periodOfNotice?: string | null;
  comment?: string | null;
}

export namespace License {
  export enum paymentCycle {
    month = "monatlich",
    year = "jährlich",
    once = "einmalig"
  }
}