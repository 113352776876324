import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class Accessory extends Resource {
    company!: Employee.Company;
    purchaseDate?: string | null;
    purchase_price?: number | null;
    billNumber?: number | null;
    orderNumber?: string | null;
    identifier!: string;
    inventory_number!: number;
    designation?: string | null;
    category!: Accessory.Category.others
    model?: string | null;
    serialnumber?: string | null;
    status!: boolean;
    scrapped!: boolean;
    comment?: string | null;
}

export namespace Accessory {
    export enum Category {
        chair = "Bürostuhl",
        doorlock = "Elektronisches Türschloss",
        television = "Fernseher",
        flipchart = "flipchart",
        coffeemaker = "Kaffeeemaschine",
        airconditioner = "Klimaanlage",
        fridge = "Kühlschrank",
        microwave = "Mikrowelle",
        presentationboard = "Moderationstafel",
        scanner = "Scanner",
        cabinet = "Schrank/Theke etc.",
        others = "Sonstiges",
        vacuum = "Staubsauger",
        fan = "ventilator",
        whiteboard = "Whiteboard",
    }
}