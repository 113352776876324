import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class Literature extends Resource {
    identifier!: string;
    purchaseDate?: string;
    purchase_price?: number | null;
    bill_number?: number | null;
    order_number?: number | null;
    type!: Literature.Type;
    isbn!: string;
    title!: string;
    author!: string;
    comment?: string | null;
}

export namespace Literature {
    export enum Type {
        option_a = "Platzhalter a",
        option_b = "Platzhalter b",
    }
}
