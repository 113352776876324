import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Employee } from "../../../models/Employee";
import { Server } from "../../../models/Server";
import {
  CREATE_SERVER_URL,
  READ_SERVER_URL,
  UPDATE_SERVER_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./serverInputFields.json";

const ServerDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [server, setServer] = useState<Server | null>(null);
  const [editedServer, setEditedServer] = useState<Server>({
    assignedEmployee: 0,
    classType: "",
    id: 0,
    identifier: "",
    biosPassword: "",
    company: Employee.Company.amanzii,
    diskCapacity: 0,
    diskByte: Server.DriveCapacityUnit.GB,
    diskType: Server.DriveType.SSD,
    hostname: "",
    serialnumber: "",
    inventory_number: 0,
    manufacturer: "",
    model: "",
    networkIp: "",
    networkMac: "",
    os_name: "",
    os_version: "",
    processorManufacturer: "",
    processorName: "",
    purchaseDate: "",
    purchase_price: 0,
    memoryCapacity: 0,
    memoryType: Server.RamType.DDR3,
    memoryByte: Server.DriveCapacityUnit.GB,
    recoveryKey: "",
    scrapped: false,
    security_key: "",
    security_type: "",
    status: false,
  });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewServer] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchServer = async () => {
      if (id && !isNewServer) {
        const data = await getItem<Server>(READ_SERVER_URL, id);
        if (data) {
          setServer(data);
          setEditedServer({
            ...data,
          });
        }
      }
    };

    fetchServer();
  }, [id, isNewServer]);

  const handleSaveChanges = async () => {
    if (isNewServer) {
      await createItem<Server>(CREATE_SERVER_URL, editedServer);
    } else if (id) {
      await updateItem<Server>(UPDATE_SERVER_URL, id, editedServer);
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedServer((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedServer[field.id as keyof Server], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedServer((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  const ServerComponent = ({ server }: { server: Server }) => {
    return (
      <div>
        <p>Status: {status_to_words(server.status)}</p>
        <p>Scrapped: {scrapped_to_words(server.scrapped)}</p>
      </div>
    );
  };

  if (!server && !isNewServer) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewServer ? "Neuen Server anlegen" : "Serverdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ServerDetail;
