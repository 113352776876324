import axios from "axios";

import { toast } from "react-toastify";

const createItem = async <T,>(url: string, data: T): Promise<number> => {
  try {
    const response = await axios.post(url, data);
    if (response.status === 201) {
      toast.success("Element erfolgreich gespeichert");
      return response.data.id;
    }
    return 1;
  } catch (error) {
    toast.error("Fehler beim Speichern");
    return 0;
  }
};

export default createItem;
