import LogoutIcon from "@mui/icons-material/Logout";
import { Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useStyle } from "./Sidebar.styles";

interface GroupedMenuItems {
  [key: string]: {
    items: { [key: string]: string };
  };
}

const Sidebar = () => {
  const style = useStyle();

  const general = [
    { name: "Dashboard", path: "/" },
    { name: "Mitarbeiter", path: "/employee" },
  ];

  const settings = [
    { name: "Profil", path: "/profile" },
    { name: "Einstellungen", path: "/settings" },
  ];

  const assets = [
    { name: "Büroausstattungen", path: "/accessory" },
    { name: "Computer", path: "/computer" },
    { name: "Fächer", path: "/compartment" },
    { name: "Festnetztelefone", path: "/landline" },
    { name: "Festnetzverträge", path: "/landlineContract" },
    { name: "Kunden-Hardware", path: "/customerHardware" },
    { name: "Literatur", path: "/literature" },
    { name: "Lizenz", path: "/license" },
    { name: "Mobiltelefone", path: "/mobilephone" },
    { name: "Monitor", path: "/monitor" },
    { name: "Netzwerkgeräte", path: "/networkDevice" },
    { name: "Räume", path: "/room" },
    { name: "Server", path: "/server" },
    { name: "SIM-Verträge", path: "/contract" },
    { name: "Tische", path: "/table" },
    { name: "Türzugang", path: "/doorAccess" },
    { name: "Virtuelle Maschinen", path: "/virtualmachine" },
  ];

  return (
    <nav className="sidebar-container">
      <div style={style.logo}>
        <img
          style={style.logo_img}
          src={`${process.env.PUBLIC_URL}/Logo.png`}
          alt="Logo"
        />
        <span style={style.logo_name}>ziings</span>
      </div>
      <ul>
        {general.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        <Divider style={style.divider} />
        {settings.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        <li key="Logout">
          <NavLink
            to="/login"
            onClick={() => toast.error("Logout noch nicht implementiert.")}
          >
            <div style={style.logout}>
              Logout <LogoutIcon style={style.logout_icon} />
            </div>
          </NavLink>
        </li>
        <Divider style={style.divider} />

        {assets.map((item) => (
          <li key={item.name}>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={item.path}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
