import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Virtualmachine } from "../../../models/Virtualmachine";
import {
  DELETE_VIRTUALMACHINE_URL,
  READ_VIRTUALMACHINES_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const VirtualmachineOverview: React.FC = () => {
  const navigate = useNavigate();
  const [virtualmachines, setVirtualmachines] = useState<Virtualmachine[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentVirtualmachines = virtualmachines.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "virtualmachine";

  useEffect(() => {
    const fetchVirtualmachines = async () => {
      const data = await getItems<Virtualmachine[]>(READ_VIRTUALMACHINES_URL);
      if (data) {
        setVirtualmachines(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchVirtualmachines();
  }, []);

  const handleDelete = async (viartualmachine: Virtualmachine) => {
    const deleted = await deleteItem(
      DELETE_VIRTUALMACHINE_URL,
      viartualmachine.id!
    );
    if (deleted) {
      setVirtualmachines((prevVirtualmachine) =>
        prevVirtualmachine.filter((comp) => comp.id !== viartualmachine.id)
      );
      setTotalPages(Math.ceil(virtualmachines.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht virtuelle Maschinen</h1>

            <BasicButton
              buttonTitle={"Neue virtuelle Maschine anlegen"}
              onClick={() =>
                navigateToCreate(navigate, `/virtualmachine/create`)
              }
            />
          </div>
          <div className="number">
            Alle virtuelle Maschinen {virtualmachines.length}
          </div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Hostname</th>
                  <th>Anzahl Kerne</th>
                  <th>Betriebssystem</th>
                  <th>Unternehmen</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentVirtualmachines.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentVirtualmachines.map((virtualmachine) => (
                    <tr key={virtualmachine.id}>
                      <td>{virtualmachine.hostname}</td>
                      <td>{virtualmachine.processor_core_count}</td>
                      <td>{virtualmachine.os_name}</td>
                      <td>{virtualmachine.company}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              virtualmachine.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              virtualmachine.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(virtualmachine)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default VirtualmachineOverview;
