import React from 'react';
import axios from 'axios';
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BasicButton from "../../components/buttons/BasicButton";
import { useStyle } from "./Login.styles";
import { useMsal } from '@azure/msal-react';

const Login: React.FC = () => {
  const style = useStyle();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [message, setMessage] = React.useState('');

  const handleLogin = () => {
    instance.loginRedirect({
      scopes: ["User.Read"],
    }).catch(error => {
      console.error(error);
      toast.error("Login fehlgeschlagen.");
    });
  };

  React.useEffect(() => {
    const checkToken = async () => {
      if (accounts.length > 0) {
        const account = accounts[0];
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ['User.Read'],
            account: account,
          });

          const token = tokenResponse.accessToken;

          try {
            const response = await axios.get('https://admin.qs1.ziings.amanzii-test.com/verify-token/', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setMessage(response.data.message);
            toast.success("Login erfolgreich.");
            navigate("/");
          } catch (error) {
            console.error(error);
            setMessage('Token is invalid');
          }
        } catch (tokenError) {
          console.error(tokenError);
          setMessage('Failed to acquire token');
        }
      } else {
        setMessage('No accounts available');
      }
    };

    checkToken();
  }, [instance, accounts, navigate]);

  return (
      <div style={style.page}>
        <div style={style.content}>
          <div style={style.logo}>
            <img
                style={style.logo_img}
                src={`${process.env.PUBLIC_URL}/Logo.png`}
                alt="Logo"
            />
            <span style={style.logo_name}>ziings</span>
          </div>

          <Divider style={style.divider} orientation="vertical" />

          <div>
            <p>{message}</p>
            <BasicButton
                buttonTitle="Login mit Microsoft Azure"
                onClick={handleLogin}
            />
          </div>
        </div>
      </div>
  );
};

export default Login;
