import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Compartment } from "../../../models/Compartment";
import {
  DELETE_COMPARTMENT_URL,
  READ_COMPARTMENTS_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const CompartmentOverview: React.FC = () => {
  const navigate = useNavigate();
  const [compartments, setCompartments] = useState<Compartment[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentCompartments = compartments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "compartment";

  useEffect(() => {
    const fetchCompartments = async () => {
      const data = await getItems<Compartment[]>(READ_COMPARTMENTS_URL);
      if (data) {
        setCompartments(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchCompartments();
  }, []);

  const handleDelete = async (compartment: Compartment) => {
    const deleted = await deleteItem(DELETE_COMPARTMENT_URL, compartment.id!);
    if (deleted) {
      setCompartments((prevCompartments) =>
        prevCompartments.filter((comp) => comp.id !== compartment.id)
      );
      setTotalPages(Math.ceil(compartments.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Fächer</h1>
            <BasicButton
              buttonTitle={"Neues Fach anlegen"}
              onClick={() => navigateToCreate(navigate, `/compartment/create`)}
            />
          </div>
          <div className="number">Alle Fächer ({compartments.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Anmerkung</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentCompartments.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentCompartments.map((compartment) => (
                    <tr key={compartment.id}>
                      <td>{compartment.comment}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              compartment.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              compartment.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(compartment)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default CompartmentOverview;
