import { TableColumn } from "../components/table/CollapsibleTable.interface";

export const ONBOARDING_TABLE_COLUMNS: TableColumn[] = [
  { field: "firstName", label: "Vorname" },
  { field: "lastName", label: "Nachname" },
  { field: "entryDate", label: "Eintrittsdatum" },
  { field: "exitDate", label: "Austrittsdatum" },
  { field: "departments", label: "Abteilungen" },
  { field: "assetAmount", label: "Assetanzahl" },
];
