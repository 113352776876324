const BASE_URL = "https://admin.qs1.ziings.amanzii-test.com/api";
export const CREATE_EMPLOYEE_URL = `${BASE_URL}/createEmployee/`;
export const READ_EMPLOYEES_URL = `${BASE_URL}/getEmployees`;
export const READ_EMPLOYEE_URL = `${BASE_URL}/getEmployee`;
export const DELETE_EMPLOYEE_URL = `${BASE_URL}/deleteEmployee`;
export const UPDATE_EMPLOYEE_URL = `${BASE_URL}/updateEmployee`;
export const GET_UNASSIGNED_RESOURCES_URL = `${BASE_URL}/getUnassignedResources`;
export const GET_RESOURCE_BY_EMPLOYEE = `${BASE_URL}/getResourceByEmployee/`;
export const CREATE_COMPUTER_URL = `${BASE_URL}/createComputer/`;
export const READ_COMPUTERS_URL = `${BASE_URL}/getComputer`;
export const READ_COMPUTER_URL = `${BASE_URL}/getComputer`;
export const DELETE_COMPUTER_URL = `${BASE_URL}/deleteComputer`;
export const UPDATE_COMPUTER_URL = `${BASE_URL}/updateComputer`;
export const CREATE_MONITOR_URL = `${BASE_URL}/createMonitor/`;
export const READ_MONITOR_URL = `${BASE_URL}/getMonitor`;
export const READ_MONITORS_URL = `${BASE_URL}/getMonitors`;
export const DELETE_MONITOR_URL = `${BASE_URL}/deleteMonitor`;
export const UPDATE_MONITOR_URL = `${BASE_URL}/updateMonitor`;
export const CREATE_LICENSE_URL = `${BASE_URL}/createLicense/`;
export const READ_LICENSE_URL = `${BASE_URL}/getLicense`;
export const READ_LICENSES_URL = `${BASE_URL}/getLicenses`;
export const DELETE_LICENSE_URL = `${BASE_URL}/deleteLicense`;
export const UPDATE_LICENSE_URL = `${BASE_URL}/updateLicense`;
export const CREATE_MOBILEPHONE_URL = `${BASE_URL}/createMobilephone/`;
export const READ_MOBILEPHONE_URL = `${BASE_URL}/getMobilephone`;
export const READ_MOBILEPHONES_URL = `${BASE_URL}/getMobilephones`;
export const DELETE_MOBILEPHONE_URL = `${BASE_URL}/deleteMobilephone`;
export const UPDATE_MOBILEPHONE_URL = `${BASE_URL}/updateMobilephone`;
export const CREATE_TABLE_URL = `${BASE_URL}/createTable/`;
export const READ_TABLE_URL = `${BASE_URL}/getTable`;
export const READ_TABLES_URL = `${BASE_URL}/getTables`;
export const DELETE_TABLE_URL = `${BASE_URL}/deleteTable`;
export const UPDATE_TABLE_URL = `${BASE_URL}/updateTable`;
export const CREATE_DOORACCESS_URL = `${BASE_URL}/createDooraccess/`;
export const READ_DOORACCESS_URL = `${BASE_URL}/getDooraccess`;
export const READ_DOORACCESSES_URL = `${BASE_URL}/getDooraccesses`;
export const DELETE_DOORACCESS_URL = `${BASE_URL}/deleteDooraccess`;
export const UPDATE_DOORACCESS_URL = `${BASE_URL}/updateDooraccess`;
export const CREATE_CONTRACT_URL = `${BASE_URL}/createContract/`;
export const READ_CONTRACT_URL = `${BASE_URL}/getContract`;
export const READ_CONTRACTS_URL = `${BASE_URL}/getContracts`;
export const DELETE_CONTRACT_URL = `${BASE_URL}/deleteContract`;
export const UPDATE_CONTRACT_URL = `${BASE_URL}/updateContract`;
export const CREATE_LANDLINE_URL = `${BASE_URL}/createLandline/`;
export const READ_LANDLINE_URL = `${BASE_URL}/getLandline`;
export const READ_LANDLINES_URL = `${BASE_URL}/getLandlines`;
export const DELETE_LANDLINE_URL = `${BASE_URL}/deleteLandline`;
export const UPDATE_LANDLINE_URL = `${BASE_URL}/updateLandline`;
export const CREATE_VIRTUALMACHINE_URL = `${BASE_URL}/createVirtualMachine/`;
export const READ_VIRTUALMACHINE_URL = `${BASE_URL}/getVirtualMachine`;
export const READ_VIRTUALMACHINES_URL = `${BASE_URL}/getVirtualMachines`;
export const DELETE_VIRTUALMACHINE_URL = `${BASE_URL}/deleteVirtualMachine`;
export const UPDATE_VIRTUALMACHINE_URL = `${BASE_URL}/updateVirtualMachine`;
export const CREATE_ACCESSORY_URL = `${BASE_URL}/createAccessory/`;
export const READ_ACCESSORY_URL = `${BASE_URL}/getAccessory`;
export const READ_ACCESSORIES_URL = `${BASE_URL}/getAccessories`;
export const DELETE_ACCESSORY_URL = `${BASE_URL}/deleteAccessory`;
export const UPDATE_ACCESSORY_URL = `${BASE_URL}/updateAccessory`;
export const CREATE_LANDLINECONTRACT_URL = `${BASE_URL}/createLandlineContract/`;
export const READ_LANDLINECONTRACT_URL = `${BASE_URL}/getLandlineContract`;
export const READ_LANDLINECONTRACTS_URL = `${BASE_URL}/getLandlineContracts`;
export const DELETE_LANDLINECONTRACT_URL = `${BASE_URL}/deleteLandlineContract`;
export const UPDATE_LANDLINECONTRACT_URL = `${BASE_URL}/updateLandlineContract`;
export const CREATE_COMPARTMENT_URL = `${BASE_URL}/createCompartment/`;
export const READ_COMPARTMENT_URL = `${BASE_URL}/getCompartment`;
export const READ_COMPARTMENTS_URL = `${BASE_URL}/getCompartments`;
export const DELETE_COMPARTMENT_URL = `${BASE_URL}/deleteCompartment`;
export const UPDATE_COMPARTMENT_URL = `${BASE_URL}/updateCompartment`;
export const CREATE_CUSTOMERHARDWARE_URL = `${BASE_URL}/createCustomerHardware/`;
export const READ_CUSTOMERHARDWARE_URL = `${BASE_URL}/getCustomerHardware`;
export const READ_CUSTOMERHARDWARES_URL = `${BASE_URL}/getCustomerHardwares`;
export const DELETE_CUSTOMERHARDWARE_URL = `${BASE_URL}/deleteCustomerHardware`;
export const UPDATE_CUSTOMERHARDWARE_URL = `${BASE_URL}/updateCustomerHardware`;
export const CREATE_LITERATURE_URL = `${BASE_URL}/createLiterature/`;
export const READ_LITERATURE_URL = `${BASE_URL}/getLiterature`;
export const READ_LITERATURES_URL = `${BASE_URL}/getLiteratures`;
export const DELETE_LITERATURE_URL = `${BASE_URL}/deleteLiterature`;
export const UPDATE_LITERATURE_URL = `${BASE_URL}/updateLiterature`;
export const CREATE_NETWORKDEVICE_URL = `${BASE_URL}/createNetworkDevice/`;
export const READ_NETWORKDEVICE_URL = `${BASE_URL}/getNetworkDevice`;
export const READ_NETWORKDEVICES_URL = `${BASE_URL}/getNetworkDevices`;
export const DELETE_NETWORKDEVICE_URL = `${BASE_URL}/deleteNetworkDevice`;
export const UPDATE_NETWORKDEVICE_URL = `${BASE_URL}/updateNetworkDevice`;
export const CREATE_ROOM_URL = `${BASE_URL}/createRoom/`;
export const READ_ROOM_URL = `${BASE_URL}/getRoom`;
export const READ_ROOMS_URL = `${BASE_URL}/getRooms`;
export const DELETE_ROOM_URL = `${BASE_URL}/deleteRoom`;
export const UPDATE_ROOM_URL = `${BASE_URL}/updateRoom`;
export const CREATE_SERVER_URL = `${BASE_URL}/createServer/`;
export const READ_SERVER_URL = `${BASE_URL}/getServer`;
export const READ_SERVERS_URL = `${BASE_URL}/getServers`;
export const DELETE_SERVER_URL = `${BASE_URL}/deleteServer`;
export const UPDATE_SERVER_URL = `${BASE_URL}/updateServer`;
export const READ_ONBOARDING_PEOPLE_URL = `${BASE_URL}/onboarding`;
export const READ_OFFBOARDING_PEOPLE_URL = `${BASE_URL}/offboarding`;
