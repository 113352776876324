import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment, useState } from "react";
import { CollapsibleTableProps } from "./CollapsibleTable.interface";
import { useStyle } from "./CollapsibleTable.styles";
import { formatCellContent } from "./CollapsibleTable.utils";

const CollapsibleTable = (props: CollapsibleTableProps) => {
  const style = useStyle();
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});

  const toggleCollapse = (rowIndex: number) => {
    setOpenRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  return (
    <TableContainer>
      <Table sx={style.table}>
        <TableHead>
          <TableRow>
            {props.collapsibleRows && <TableCell />}
            {props.columns.map((column) => (
              <TableCell key={column.field}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, rowIndex) => (
            <Fragment key={rowIndex}>
              <TableRow>
                {props.collapsibleRows && (
                  <TableCell onClick={() => toggleCollapse(row.id)}>
                    {openRows[row.id] ? (
                      <ExpandLess sx={style.iconColor} />
                    ) : (
                      <ExpandMore sx={style.iconColor} />
                    )}
                  </TableCell>
                )}
                {props.columns.map((column) => (
                  <TableCell key={column.field}>
                    {formatCellContent(row[column.field])}
                  </TableCell>
                ))}
              </TableRow>
              {props.collapsibleRows && (
                <TableRow sx={style.collapsibleRow}>
                  <TableCell />
                  <TableCell colSpan={props.columns.length}>
                    <Collapse
                      in={openRows[row.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {/* TODO: adjust when using real data */}
                      <div>{formatCellContent(row["assets"])}</div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
