import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { LandlineContract } from "../../../models/LandlineContract";
import {
  DELETE_LANDLINECONTRACT_URL,
  READ_LANDLINECONTRACTS_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const LandlineContractOverview: React.FC = () => {
  const navigate = useNavigate();
  const [landlineContracts, setLandlineContracts] = useState<
    LandlineContract[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentLandlineContracts = landlineContracts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "landlineContract";

  useEffect(() => {
    const fetchLandlineContracts = async () => {
      const data = await getItems<LandlineContract[]>(
        READ_LANDLINECONTRACTS_URL
      );
      if (data) {
        setLandlineContracts(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchLandlineContracts();
  }, []);

  const handleDelete = async (landlineContract: LandlineContract) => {
    const deleted = await deleteItem(
      DELETE_LANDLINECONTRACT_URL,
      landlineContract.id!
    );
    if (deleted) {
      setLandlineContracts((prevLandlineContracts) =>
        prevLandlineContracts.filter((comp) => comp.id !== landlineContract.id)
      );
      setTotalPages(Math.ceil(landlineContracts.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Festnetzverträge</h1>
            <BasicButton
              buttonTitle={"Neuen Festnetzvertrag anlegen"}
              onClick={() =>
                navigateToCreate(navigate, `/landlineContract/create`)
              }
            />
          </div>
          <div className="number">
            Alle Festnetzverträge ({landlineContracts.length})
          </div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Kundennummer</th>
                  <th>Anbieter</th>
                  <th>Telefon</th>
                  <th>Unternehmen</th>
                  <th>Monatliche Kosten (exkl. Rabatt)</th>
                  <th>Monatliche Kosten (inkl. Rabatt)</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentLandlineContracts.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentLandlineContracts.map((landlineContract) => (
                    <tr key={landlineContract.id}>
                      <td>{landlineContract.customerNr}</td>
                      <td>{landlineContract.phoneNumber}</td>
                      <td>{landlineContract.company}</td>
                      <td>{landlineContract.monthlyCost}</td>
                      <td>{landlineContract.computed_monthly_cost}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              landlineContract.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              landlineContract.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(landlineContract)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default LandlineContractOverview;
