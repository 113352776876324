import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Accessory } from "../../../models/Accessory";
import {
  DELETE_ACCESSORY_URL,
  READ_ACCESSORIES_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const AccessoryOverview: React.FC = () => {
  const navigate = useNavigate();
  const [accessories, setAccessories] = useState<Accessory[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentAccessories = accessories.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "accessory";

  useEffect(() => {
    const fetchAccessories = async () => {
      const data = await getItems<Accessory[]>(READ_ACCESSORIES_URL);
      if (data) {
        setAccessories(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchAccessories();
  }, []);

  const handleDelete = async (accessory: Accessory) => {
    const deleted = await deleteItem(DELETE_ACCESSORY_URL, accessory.id!);
    if (deleted) {
      setAccessories((prevAccessories) =>
        prevAccessories.filter((comp) => comp.id !== accessory.id)
      );
      setTotalPages(Math.ceil(accessories.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Büroausstattungen</h1>
            <BasicButton
              buttonTitle={"Neue Büroausstattungen anlegen"}
              onClick={() => navigateToCreate(navigate, `/accessory/create`)}
            />
          </div>
          <div className="number">
            Alle Büroausstattungen ({accessories.length})
          </div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Kennung</th>
                  <th>Kategorie</th>
                  <th>Modell</th>
                  <th>Status</th>
                  <th>Verschrottet</th>
                  <th>Seriennummer</th>
                  <th>Zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentAccessories.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentAccessories.map((accessory) => (
                    <tr key={accessory.id}>
                      <td>{accessory.identifier}</td>
                      <td>{accessory.category}</td>
                      <td>{accessory.model}</td>
                      <td>{status_to_words(accessory.status)}</td>
                      <td>{scrapped_to_words(accessory.scrapped)}</td>
                      <td>{accessory.serialnumber}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              accessory.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              accessory.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(accessory)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default AccessoryOverview;
