import { Button } from "@mui/material";
import { useStyle } from "./BasicButton.styles";

const BasicButton = (props: BasicButtonProps) => {
  if (!props.buttonTitle && !props.buttonIcon) {
    throw new Error(
      "Either >buttonTitle< or >buttonIcon< must be specified when using Button Component.\n"
    );
  }
  const style = useStyle();
  return (
    <Button
      onClick={props.onClick}
      sx={{
        ...style.button,
        ...props.sx,
      }}
    >
      {props.buttonIcon && props.buttonIcon}
      {props.buttonTitle && props.buttonTitle}
    </Button>
  );
};

export default BasicButton;
