import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

const updateItem = async <T,>(
  url: string,
  id: string,
  data: T
): Promise<boolean> => {
  try {
    const response: AxiosResponse = await axios.put(`${url}/${id}`, data);
    toast.success("erfolgreich aktualisiert");
    return response.status === 200;
  } catch (error) {
    toast.error("Fehler beim aktualisieren");
    return false;
  }
};

export default updateItem;
