import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import TabBar from "../../components/tab-bar/TabBar";
import CollapsibleTable from "../../components/table/CollapsibleTable";
import { ONBOARDING_TABLE_COLUMNS } from "../../constants/tableColumns";
import { OnOffboardingBE, OnOffboardingFE } from "../../models/OnOffboarding";
import {
  READ_OFFBOARDING_PEOPLE_URL,
  READ_ONBOARDING_PEOPLE_URL,
} from "../../services/apiRoutes";
import getItems from "../../services/getItems";
import { transformData } from "./Dashboard.utils";

const Dashboard = () => {
  const [onboarding, setOnboarding] = useState<OnOffboardingFE[]>([]);
  const [offboarding, setOffboarding] = useState<OnOffboardingFE[]>([]);
  const [rows, setRows] = useState(onboarding);

  useEffect(() => {
    // Fetch Onboarding Data
    (async () => {
      const data = await getItems<OnOffboardingBE[]>(
        READ_ONBOARDING_PEOPLE_URL
      );
      if (data) {
        const transData = transformData(data);
        setOnboarding(transData);
        setRows(transData); // inital rows
      }
    })();

    // Fetch Offboarding Data
    (async () => {
      const data = await getItems<OnOffboardingBE[]>(
        READ_OFFBOARDING_PEOPLE_URL
      );
      if (data) setOffboarding(transformData(data));
    })();
  }, []);

  return (
    <Layout>
      <h1>Dashboard</h1>
      <TabBar
        tabs={[
          { label: "Onboarding", onClick: () => setRows(onboarding) },
          { label: "Offboarding", onClick: () => setRows(offboarding) },
        ]}
      ></TabBar>
      <CollapsibleTable
        columns={ONBOARDING_TABLE_COLUMNS}
        rows={rows}
        // TODO: at first we need the assigned assets from backend to show them as collapsible content
        // collapsibleRows
      ></CollapsibleTable>
    </Layout>
  );
};

export default Dashboard;
