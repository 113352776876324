import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class NetworkDevice extends Resource {
  company!: Employee.Company;
  identifier!: string;
  purchaseDate?: string;
  purchase_price?: number | null;
  manufacturer!: string;
  model!: string;
  inventory_number?: number | null;
  category!: NetworkDevice.Type;
  firmware!: string;
  scrapped!: boolean;
  status!: boolean;
  networkIp?: string | null;
  networkMac?: string | null;
  bill_number?: string | null;
  order_number?: string | null;
  serialnumber?: string | null;
  user?: string | null;
  password?: string | null;
  port_type!: NetworkDevice.Type;
  quantity?: string | null;
  comment?: string | null;
}

export namespace NetworkDevice {
  export enum Type {
    option_a = "Platzhalter a",
    option_b = "Platzhalter b",
  }
}
