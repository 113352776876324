import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Mobilephone } from "../../../models/Mobilephone";
import {
  DELETE_MOBILEPHONE_URL,
  READ_MOBILEPHONES_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";
const ITEMS_PER_PAGE = 5;
const MobilephoneOverview: React.FC = () => {
  const navigate = useNavigate();
  const [mobilephone, setMobilephone] = useState<Mobilephone[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentMobilephones = mobilephone.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "mobilephone";
  useEffect(() => {
    const fetchMobilePhones = async () => {
      const data = await getItems<Mobilephone[]>(READ_MOBILEPHONES_URL);
      if (data) {
        setMobilephone(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchMobilePhones();
  }, []);
  const handleDelete = async (phone: Mobilephone) => {
    const deleted = await deleteItem(DELETE_MOBILEPHONE_URL, phone.id!);
    if (deleted) {
      setMobilephone((prevEmployees) =>
        prevEmployees.filter((emp) => emp.id !== phone.id)
      );
      setTotalPages(Math.ceil(mobilephone.length / ITEMS_PER_PAGE));
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Handy</h1>
            <BasicButton
              buttonTitle={"Neues Mobiltelefon anlegen"}
              onClick={() => navigateToCreate(navigate, `/mobilephone/create`)}
            />
          </div>
          <div className="number">Alle Handys ({mobilephone.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>IMEI</th>
                  <th>Hersteller</th>
                  <th>Modell</th>
                  <th>Unternehmen</th>
                  <th>Status</th>
                  <th>Verschrottet</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentMobilephones.length === 0 ? (
                  <tr>
                    <td colSpan={8}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentMobilephones.map((mobilephone) => (
                    <tr key={mobilephone.id}>
                      <td>{mobilephone.imei1}</td>
                      <td>{mobilephone.manufacturer}</td>
                      <td>{mobilephone.model}</td>
                      <td>{mobilephone.company}</td>
                      <td>{status_to_words(mobilephone.status)}</td>
                      <td>{scrapped_to_words(mobilephone.scrapped)}</td>
                      <td>{}</td>
                      <td className="options">
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              mobilephone.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              mobilephone.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(mobilephone)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};
export default MobilephoneOverview;
