import { Box, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useStyle } from "./TabBar.styles";

const TabBar = (props: TabBarProps) => {
  const style = useStyle();
  const [value, setValue] = useState(props.tabs[0].label);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} sx={style.tabBar}>
        {props.tabs.map((tab) => (
          <Tab
            key={tab.label}
            value={tab.label}
            label={tab.label}
            onClick={tab.onClick}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabBar;
