import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { License } from "../../../models/License";
import {
  DELETE_LICENSE_URL,
  READ_LICENSES_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import { handleSiteView } from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 4;

const LicenseOverview: React.FC = () => {
  const navigate = useNavigate();
  const [licenses, setLicenses] = useState<License[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [, setSelectedLicense] = useState<License | null>(null);
  const resource = "license";

  useEffect(() => {
    const fetchLicenses = async () => {
      const data = await getItems<License[]>(READ_LICENSES_URL);
      if (data) {
        setLicenses(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchLicenses();
  }, []);

  const handleDelete = async (license: License) => {
    const deleted = await deleteItem(DELETE_LICENSE_URL, license.id!);
    if (deleted) {
      setLicenses((prevLicenses) =>
        prevLicenses.filter((mon) => mon.id !== license.id)
      );
      setTotalPages(Math.ceil(licenses.length / ITEMS_PER_PAGE));
    }
  };

  const handleResourceClick = (license: License) => {
    setSelectedLicense(license);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const navigateToCreate = () => {
    const createUrl = `/license/create`;
    navigate(createUrl, { state: { editMode: true } });
  };
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentLicenses = licenses.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Lizenzen</h1>
            <BasicButton
              buttonTitle={"Neue Lizenz anlegen"}
              onClick={() => navigateToCreate()}
            />
          </div>
          <div className="number">Alle Lizenzen ({licenses.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Lizenzgeber</th>
                  <th>Lizenztyp</th>
                  <th>Lizenzschlüssel</th>
                  <th>Anmerkung</th>
                  <th>Unternehmen</th>
                  <th>Zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentLicenses.map((license) => (
                  <tr key={license.id}>
                    <td>{license.licensor}</td>
                    <td>{license.licenseType}</td>
                    <td>{license.licenseKey}</td>
                    <td>{license.comment}</td>
                    <td>{license.company}</td>
                    <td></td>
                    <td></td>
                    <td>
                      <OptionsButtonGroup
                        viewOnClick={() =>
                          handleSiteView(
                            navigate,
                            resource,
                            license.id!!,
                            false
                          )
                        }
                        editOnClick={() =>
                          handleSiteView(navigate, resource, license.id!!, true)
                        }
                        deleteOnClick={() => handleDelete(license)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default LicenseOverview;
