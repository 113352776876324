import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Computer } from "../../../models/Computer";
import { Employee } from "../../../models/Employee";
import {
  CREATE_COMPUTER_URL,
  READ_COMPUTER_URL,
  UPDATE_COMPUTER_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./computerInputFields.json";

const ComputerDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [computer, setComputer] = useState<Computer | null>(null);
  const [editedComputer, setEditedComputer] = useState<Computer>({
    assignedEmployee: 0,
    classType: "",
    id: 0,
    identifier: "",
    biosPassword: "",
    company: Employee.Company.amanzii,
    diskCapacity: 0,
    diskByte: Computer.DriveCapacityUnit.GB,
    diskType: Computer.DriveType.SSD,
    hostname: "",
    imei: "",
    inventory_number: 0,
    manufacturer: "",
    model: "",
    networkIp: "",
    networkMac: "",
    os_name: "",
    os_version: "",
    processorManufacturer: "",
    processorName: "",
    purchase_date: "",
    purchase_price: 0,
    memoryCapacity: 0,
    memoryType: Computer.RamType.DDR2,
    memoryByte: Computer.DriveCapacityUnit.GB,
    recoveryKey: "",
    scrapped: false,
    security_key: "",
    security_type: "",
    status: false,
    type: Computer.Type.laptop,
  });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewComputer] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchComputer = async () => {
      if (id && !isNewComputer) {
        const data = await getItem<Computer>(READ_COMPUTER_URL, id);
        if (data) {
          setComputer(data);
          setEditedComputer({
            ...data,
          });
        }
      }
    };

    fetchComputer();
  }, [id, isNewComputer]);

  const handleSaveChanges = async () => {
    if (isNewComputer) {
      await createItem<Computer>(CREATE_COMPUTER_URL, editedComputer);
    } else if (id) {
      await updateItem<Computer>(UPDATE_COMPUTER_URL, id, editedComputer);
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedComputer((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedComputer[field.id as keyof Computer], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedComputer((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  const ComputerComponent = ({ computer }: { computer: Computer }) => {
    return (
      <div>
        <p>Status: {status_to_words(computer.status)}</p>
        <p>Scrapped: {scrapped_to_words(computer.scrapped)}</p>
      </div>
    );
  };

  if (!computer && !isNewComputer) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewComputer ? "Neuen Computer anlegen" : "Computerdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ComputerDetail;
