import axios from "axios";
import { toast } from "react-toastify";

const deleteItem = async <T,>(
  url: string,
  itemId: number
): Promise<boolean> => {
  try {
    const shouldDelete = window.confirm(
      "Möchten Sie diese Ressource wirklich löschen?"
    );

    if (!shouldDelete) {
      console.log("Löschen abgebrochen");
      return false;
    }

    const response = await axios.delete<T>(`${url}/${itemId}`);

    if (response.status === 204) {
      toast.success("Element erfolgreich gelöscht");
      return true;
    } else {
      toast.error("Fehler beim Löschen des Elements");
      return false;
    }
  } catch (error) {
    toast.error("Fehler beim Löschen des Elements");
    return false;
  }
};

export default deleteItem;
