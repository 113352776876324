import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../components/FormComponent";
import Layout from "../../components/Layout";
import BasicButton from "../../components/buttons/BasicButton";
import { Employee } from "../../models/Employee";
import {
  CREATE_EMPLOYEE_URL,
  READ_EMPLOYEE_URL,
  UPDATE_EMPLOYEE_URL,
} from "../../services/apiRoutes";
import createItem from "../../services/createItem";
import getItem from "../../services/getItem";
import updateItem from "../../services/updateItem";
import "../../styles/Details.css";
import sectionsData from "./employeeInputFields.json";

const EmployeeDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [employee, setEmployee] = useState<Employee>();
  const [editedEmployee, setEditedEmployee] = useState<Employee>({
    id: 0,
    firstName: "",
    lastName: "",
    employmentDate: "",
    leaveDate: null,
    company: Employee.Company.amanzii,
    workplace: Employee.Workplace.Remote,
    department: Employee.Department.Development,
    role: Employee.Role.Developer,
    status: false,
  });
  const editMode = location.state?.editMode ?? false;
  const isNewEmployee = location.pathname.includes("/create");

  useEffect(() => {
    const fetchEmployee = async () => {
      if (id && !isNewEmployee) {
        const data = await getItem<Employee>(READ_EMPLOYEE_URL, id);
        if (data) {
          setEmployee(data);
          setEditedEmployee({
            ...data,
          });
        }
      }
    };

    fetchEmployee();
  }, [id, isNewEmployee]);

  const handleSaveChanges = async () => {
    try {
      if (isNewEmployee) {
        await createItem(CREATE_EMPLOYEE_URL, {
          ...editedEmployee,
          leaveDate: editedEmployee.leaveDate ? editedEmployee.leaveDate : null,
        });
      } else if (id) {
        await updateItem(UPDATE_EMPLOYEE_URL, id, {
          ...editedEmployee,
          leaveDate: editedEmployee.leaveDate ? editedEmployee.leaveDate : null,
        });
      }
    } catch (error) {
      console.error("Error saving employee:", error);
    }
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedEmployee[field.id as keyof Employee],
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedEmployee((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!employee && !isNewEmployee) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewEmployee ? "Neuen Mitarbeiter anlegen" : "Mitarbeiterdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>
        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EmployeeDetails;
