import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class Server extends Resource {
  company!: Employee.Company;
  identifier!: string;
  purchaseDate?: string;
  purchase_price?: number | null;
  manufacturer!: string;
  model!: string;
  inventory_number?: number | null;
  serialnumber!: string;
  hostname!: string;
  security_type?: string | null;
  security_key?: string | null;
  recoveryKey?: string | null;
  biosPassword?: string | null;
  os_name!: string;
  os_version!: string;
  processorManufacturer!: string;
  processorName!: string;
  scrapped!: boolean;
  status!: boolean;
  networkIp?: string | null;
  networkMac?: string | null;
  diskType!: Server.DriveType;
  diskCapacity!: number;
  diskByte!: Server.DriveCapacityUnit;
  memoryType!: Server.RamType;
  memoryCapacity!: number;
  memoryByte!: Server.DriveCapacityUnit;
}

export namespace Server {
  export enum DriveType {
    SSD = "SSD",
    HDD = "HDD",
    SSHD = "SSHD",
  }
  export enum DriveCapacityUnit {
    MB = "Megabyte",
    GB = "Gigabyte",
    TB = "Terabyte",
  }

  export enum RamType {
    DDR2 = "DDR2",
    DDR3 = "DDR3",
    DDR4 = "DDR4",
    DDR5 = "DDR5",
  }
}
