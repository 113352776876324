import { OnOffboardingBE } from "../../models/OnOffboarding";

/** This helper function transforms the on/offboarding object to fit the frontend needs.
 * @param data On/Offboarding object that comes from backend.
 * @return On/Offboarding object formatted in the way we need in frontend.
 */
export const transformData = (data: OnOffboardingBE[]) => {
  return data.map((item) => ({
    firstName: item.first_name,
    lastName: item.last_name,
    entryDate: new Date(item.profile.start_date),
    exitDate:
      item.profile.end_date !== null ? new Date(item.profile.end_date) : null,
    departments: item.profile.departments.map((dept) => dept.name),
    assetAmount: -1, // TODO: no data from db
  }));
};
