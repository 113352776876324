import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Computer } from "../../../models/Computer";
import {
  DELETE_COMPUTER_URL,
  READ_COMPUTERS_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import formatDateString from "../../../services/dateUtils";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const ComputerOverview: React.FC = () => {
  const navigate = useNavigate();
  const [computers, setComputers] = useState<Computer[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentComputers = computers.slice(indexOfFirstItem, indexOfLastItem);
  const resource = "computer";

  useEffect(() => {
    const fetchComputers = async () => {
      const data = await getItems<Computer[]>(READ_COMPUTERS_URL);
      if (data) {
        setComputers(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchComputers();
  }, []);

  const handleDelete = async (computer: Computer) => {
    const deleted = await deleteItem(DELETE_COMPUTER_URL, computer.id!);
    if (deleted) {
      setComputers((prevComputers) =>
        prevComputers.filter((comp) => comp.id !== computer.id)
      );
      setTotalPages(Math.ceil(computers.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Computer</h1>
            <BasicButton
              buttonTitle={"Neuen Computer anlegen"}
              onClick={() => navigateToCreate(navigate, `/computer/create`)}
            />
          </div>
          <div className="number">Alle Computer ({computers.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Kennung</th>
                  <th>Firma</th>
                  <th>Modell</th>
                  <th>Prozessor</th>
                  <th>Festplatte</th>
                  <th>Arbeitsspeicher</th>
                  <th>Kaufpreis</th>
                  <th>Kaufdatum</th>
                  <th>Status</th>
                  <th>Verschrottet</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentComputers.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentComputers.map((computer) => (
                    <tr key={computer.id}>
                      <td>{computer.identifier}</td>
                      <td>{computer.company}</td>
                      <td>{computer.model}</td>
                      <td>{computer.processorName}</td>
                      <td>{computer.diskCapacity}</td>
                      <td>{computer.memoryCapacity}</td>
                      <td>{computer.purchase_price}</td>
                      <td>{formatDateString(computer.purchase_date)}</td>
                      <td>{status_to_words(computer.status)}</td>
                      <td>{scrapped_to_words(computer.scrapped)}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              computer.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              computer.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(computer)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default ComputerOverview;
