import axios from "axios";
import { toast } from "react-toastify";

const getItem = async <T,>(
  url: string,
  id: string | number
): Promise<T | null> => {
  try {
    const response = await axios.get<T>(`${url}/${id}`);
    return response.data;
  } catch (error) {
    toast.error(`Fehler beim Abrufen der Daten von ${url}/${id}`);
    return null;
  }
};

export default getItem;
