import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Computer } from "../../../models/Computer";
import { Employee } from "../../../models/Employee";
import { Virtualmachine } from "../../../models/Virtualmachine";
import {
  CREATE_VIRTUALMACHINE_URL,
  READ_VIRTUALMACHINE_URL,
  UPDATE_VIRTUALMACHINE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./virtualmachineInputFields.json";
interface EditedVirtualmachine extends Virtualmachine {
  company: Employee.Company;
}

const VirtualmachineDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [virtualmachine, setVirtualmachine] = useState<Virtualmachine>();
  const [editedVirtualmachine, setEditedVirtualmachine] =
    useState<EditedVirtualmachine>({
      name: "",
      os_name: "",
      os_version: "",
      processor_core_count: "",
      memoryCapacity: "",
      memoryByte: Computer.DriveCapacityUnit.GB,
      memoryType: "",
      storageCapacity: "",
      storageByte: Computer.DriveCapacityUnit.GB,
      encryptionType: "",
      encryptionKey: "",
      recoveryKey: "",
      ip: "",
      mac: "",
      comment: "",
      company: Employee.Company.amanzii,
      hostname: "",
    });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewVirtualmachine] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchVirtualmachine = async () => {
      if (id && !isNewVirtualmachine) {
        const data = await getItem<Virtualmachine>(READ_VIRTUALMACHINE_URL, id);
        if (data) {
          setVirtualmachine(data);
          setEditedVirtualmachine({
            ...data,
          });
        }
      }
    };

    fetchVirtualmachine();
  }, [id, isNewVirtualmachine]);

  const handleSaveChanges = async () => {
    if (isNewVirtualmachine) {
      await createItem<Virtualmachine>(
        CREATE_VIRTUALMACHINE_URL,
        editedVirtualmachine
      );
    } else if (id) {
      await updateItem<Virtualmachine>(
        UPDATE_VIRTUALMACHINE_URL,
        id,
        editedVirtualmachine
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedVirtualmachine((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedVirtualmachine[field.id as keyof Virtualmachine], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedVirtualmachine((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!virtualmachine && !isNewVirtualmachine) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewVirtualmachine
            ? "Neue virtuelle Maschine anlegen"
            : "virtuelle Machienen details"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default VirtualmachineDetail;
