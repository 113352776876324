import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import Pagination from "../../components/Paginator";
import BasicButton from "../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../components/options-button-group/OptionsButtonGroup";
import { Employee } from "../../models/Employee";
import {
  DELETE_EMPLOYEE_URL,
  READ_EMPLOYEES_URL,
} from "../../services/apiRoutes";
import formatDateString from "../../services/dateUtils";
import deleteItem from "../../services/deleteItem";
import getItems from "../../services/getItems";
import { handleSiteView, navigateToCreate } from "../../services/overviewUtils";
import "../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const EmployeeOverview: React.FC = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [, setSelectedEmployee] = useState<Employee | null>(null);
  const resource = "employee";

  useEffect(() => {
    const fetchEmployees = async () => {
      const data = await getItems<Employee[]>(READ_EMPLOYEES_URL);
      if (data) {
        setEmployees(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchEmployees();
  }, []);

  const handleDelete = async (employee: Employee) => {
    const deleted = await deleteItem(DELETE_EMPLOYEE_URL, employee.id!);
    if (deleted) {
      setEmployees((prevEmployees) =>
        prevEmployees.filter((emp) => emp.id !== employee.id)
      );
      setTotalPages(Math.ceil(employees.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentEmployees = employees.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Mitarbeiter</h1>
            <BasicButton
              buttonTitle={"Neuen Mitarbeiter anlegen"}
              onClick={() => navigateToCreate(navigate, `/employee/create`)}
            />
          </div>
          <div className="number">Alle Mitarbeiter ({employees.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th>Eintrittsdatum</th>
                  <th>Austrittsdatum</th>
                  <th>Abteilung</th>
                  <th>Rolle</th>
                  <th>Status</th>
                  <th>Zugewiesene Ressourcen</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentEmployees.length === 0 ? (
                  <tr>
                    <td colSpan={10}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentEmployees.map((employee) => (
                    <tr key={employee.id}>
                      <td>{employee.firstName}</td>
                      <td>{employee.lastName}</td>
                      <td>{formatDateString(employee.employmentDate)}</td>
                      <td>{formatDateString(employee.leaveDate ?? "")}</td>
                      <td>{employee.department}</td>
                      <td>{employee.role}</td>
                      <td>{employee.status}</td>
                      <td></td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              employee.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              employee.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(employee)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default EmployeeOverview;
