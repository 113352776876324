import React from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Toast from "./components/Toast";
import AccessoryDetail from "./pages/assets/accessory/accessoryDetails";
import AccessoryOverview from "./pages/assets/accessory/accessoryOverview";
import CompartmentDetail from "./pages/assets/compartment/compartmentDetails";
import CompartmentOverview from "./pages/assets/compartment/compartmentOverview";
import ComputerDetail from "./pages/assets/computer/computerDetails";
import ComputerOverview from "./pages/assets/computer/computerOverview";
import ContractDetails from "./pages/assets/contract/contractDetails";
import ContractOverview from "./pages/assets/contract/contractOverview";
import CustomerHardwareDetail from "./pages/assets/customerHardware/customerHardwareDetails";
import CustomerHardwareOverview from "./pages/assets/customerHardware/customerHardwareOverview";
import DooraccessDetail from "./pages/assets/dooraccess/dooraccessDetails";
import DooraccessOverview from "./pages/assets/dooraccess/dooraccessOverview";
import LandlineDetail from "./pages/assets/landline/landlineDetails";
import LandlineOverview from "./pages/assets/landline/landlineOverview";
import LandlineContractDetail from "./pages/assets/landlineContract/landlineContractDetails";
import LandlineContractOverview from "./pages/assets/landlineContract/landlineContractOverview";
import LicenseDetails from "./pages/assets/license/licenseDetails";
import LicenseOverview from "./pages/assets/license/licenseOverview";
import LiteratureDetail from "./pages/assets/literature/literatureDetails";
import LiteratureOverview from "./pages/assets/literature/literatureOverview";
import MobilephoneDetail from "./pages/assets/mobilephone/mobilephoneDetails";
import MobilephoneOverview from "./pages/assets/mobilephone/mobilephoneOverview";
import MonitorDetails from "./pages/assets/monitor/monitorDetails";
import MonitorOverview from "./pages/assets/monitor/monitorOverview";
import NetworkDeviceDetail from "./pages/assets/networkDevice/networkDeviceDetails";
import NetworkDeviceOverview from "./pages/assets/networkDevice/networkDeviceOverview";
import RoomDetail from "./pages/assets/room/roomDetails";
import RoomOverview from "./pages/assets/room/roomOverview";
import ServerDetail from "./pages/assets/server/serverDetails";
import ServerOverview from "./pages/assets/server/serverOverview";
import TableDetail from "./pages/assets/table/tableDetail";
import TableOverview from "./pages/assets/table/tableOverview";
import VirtualmachineDetail from "./pages/assets/virtualmachine/virtualmachineDetails";
import VirtualmachineOverview from "./pages/assets/virtualmachine/virtualmachineOverview";
import Dashboard from "./pages/dashboard/Dashboard";
import EmployeeDetail from "./pages/employee/EmployeeDetails";
import EmployeeOverview from "./pages/employee/EmployeeOverview";
import Login from "./pages/login/Login";
import {MsalProvider, useIsAuthenticated, useMsal} from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const AppRoutes = () => {
    const { accounts } = useMsal();
    const isAuthenticated = accounts.length > 0;
    const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={isAuthenticated ? <Dashboard /> : <Login />} />

        <Route path="/employee" element={<EmployeeOverview />}>
          <Route path=":id" element={<EmployeeDetail />} />
          <Route path="create" element={<EmployeeDetail />} />
        </Route>

        {/* assets: */}

        <Route path="/accessory" element={<AccessoryOverview />}>
          <Route path=":id" element={<AccessoryDetail />} />
          <Route path="create" element={<AccessoryDetail />} />
        </Route>

        <Route path="/compartment" element={<CompartmentOverview />}>
          <Route path=":id" element={<CompartmentDetail />} />
          <Route path="create" element={<CompartmentDetail />} />
        </Route>

        <Route path="/computer" element={<ComputerOverview />}>
          <Route path=":id" element={<ComputerDetail />} />
          <Route path="create" element={<ComputerDetail />} />
        </Route>

        <Route path="/contract" element={<ContractOverview />}>
          <Route path=":id" element={<ContractDetails />} />
          <Route path="create" element={<ContractDetails />} />
        </Route>

        <Route path="/customerHardware" element={<CustomerHardwareOverview />}>
          <Route path=":id" element={<CustomerHardwareDetail />} />
          <Route path="create" element={<CustomerHardwareDetail />} />
        </Route>

        <Route path="/dooraccess" element={<DooraccessOverview />}>
          <Route path=":id" element={<DooraccessDetail />} />
          <Route path="create" element={<DooraccessDetail />} />
        </Route>

        <Route path="/landline" element={<LandlineOverview />}>
          <Route path=":id" element={<LandlineDetail />} />
          <Route path="create" element={<LandlineDetail />} />
        </Route>

        <Route path="/landlineContract" element={<LandlineContractOverview />}>
          <Route path=":id" element={<LandlineContractDetail />} />
          <Route path="create" element={<LandlineContractDetail />} />
        </Route>

        <Route path="/license" element={<LicenseOverview />}>
          <Route path=":id" element={<LicenseDetails />} />
          <Route path="create" element={<LicenseDetails />} />
        </Route>

        <Route path="/literature" element={<LiteratureOverview />}>
          <Route path=":id" element={<LiteratureDetail />} />
          <Route path="create" element={<LiteratureDetail />} />
        </Route>

        <Route path="/mobilephone" element={<MobilephoneOverview />}>
          <Route path=":id" element={<MobilephoneDetail />} />
          <Route path="create" element={<MobilephoneDetail />} />
        </Route>

        <Route path="/monitor" element={<MonitorOverview />}>
          <Route path=":id" element={<MonitorDetails />} />
          <Route path="create" element={<MonitorDetails />} />
        </Route>

        <Route path="/networkDevice" element={<NetworkDeviceOverview />}>
          <Route path=":id" element={<NetworkDeviceDetail />} />
          <Route path="create" element={<NetworkDeviceDetail />} />
        </Route>

        <Route path="/room" element={<RoomOverview />}>
          <Route path=":id" element={<RoomDetail />} />
          <Route path="create" element={<RoomDetail />} />
        </Route>

        <Route path="/server" element={<ServerOverview />}>
          <Route path=":id" element={<ServerDetail />} />
          <Route path="create" element={<ServerDetail />} />
        </Route>

        <Route path="/table" element={<TableOverview />}>
          <Route path=":id" element={<TableDetail />} />
          <Route path="create" element={<TableDetail />} />
        </Route>

        <Route path="/virtualmachine" element={<VirtualmachineOverview />}>
          <Route path=":id" element={<VirtualmachineDetail />} />
          <Route path="create" element={<VirtualmachineDetail />} />
        </Route>
      </Routes>
  );
};

const App = () => {
  return (
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <AppRoutes />
          <Toast />
        </BrowserRouter>
      </MsalProvider>
  );
};

export default App;
