import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import "../styles/Paginator.css";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const pagesToShow = 3;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    if (currentPage > 1) {
      pageNumbers.push(
        <li key={"prev-" + currentPage}>
          <button
            title="back"
            type="button"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <NavigateBeforeIcon />
          </button>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            type="button"
            onClick={() => onPageChange(i)}
            className={currentPage === i ? "active" : ""}
          >
            {i}
          </button>
        </li>
      );
    }
    if (currentPage < totalPages) {
      pageNumbers.push(
        <li key={"next-" + currentPage}>
          <button
            title="next"
            type="button"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <NavigateNextIcon />
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination">
      <ul>
        <li key={"firstPage"}>
          <button
            title="firstPage"
            type="button"
            onClick={() => onPageChange(1)}
          >
            <FirstPageIcon />
          </button>
        </li>
        {renderPageNumbers()}
        <li key={"lastPage"}>
          <button
            title="lastPage"
            type="button"
            onClick={() => onPageChange(totalPages)}
          >
            <LastPageIcon />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
