import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class Contract extends Resource {
  provider!: string;
  customerNumber!: string;
  contractMail!: string;
  contractNumber!: string;
  hotline?: string | null;
  hotlinePassword?: string | null;
  password!: string;
  puk!: number;
  sim?: number | null;
  username!: string;
  mobileNetwork!: any;
  phoneNumber!: number;
  pin!: number;
  minimumContractDuration!: string;
  dataVolumeUnit?: Contract.CapacityUnit | null;
  dataVolume?: number | null;
  periodOfNotice!: string;
  contractStart?: string | null;
  contractEnd?: string | null;
  contractDuration!: string;
  company!: Employee.Company;
  monthlyCost?: number | null;
  monthlyDiscount?: number | null;
  computedMonthlyCost!: number;
  discountDuration?: string | null;
  comment?: string | null;
}

export namespace Contract {
  export enum CapacityUnit {
    MB = "Megabyte",
    GB = "Gigabyte",
    TB = "Terabyte",
  }
}
