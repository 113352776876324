import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Employee } from "../../../models/Employee";
import { LandlineContract } from "../../../models/LandlineContract";
import {
  CREATE_LANDLINECONTRACT_URL,
  READ_LANDLINECONTRACT_URL,
  UPDATE_LANDLINECONTRACT_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./landlineContractInputFields.json";

interface EditedLandlineContract extends LandlineContract {
  company: Employee.Company;
}

const LandlineContractDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [landlineContract, setLandlineContract] =
    useState<LandlineContract | null>(null);
  const [editedLandlineContract, setEditedLandlineContract] =
    useState<EditedLandlineContract>({
      assignedEmployee: 0,
      classType: "",
      comment: "",
      company: Employee.Company.amanzii,
      computed_monthly_cost: "",
      contractEnd: "",
      contractNr: "",
      contractStart: "",
      customerNr: "",
      discountDuration: "",
      id: 0,
      identifier: "",
      phoneNumber: "",
      minimumContractDuration: "",
      monthlyCost: "",
      monthlyDiscount: "",
      periodOfNotice: "",
      pin: 0,
      provider: "",
    });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewLandlineContract] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchLandline = async () => {
      if (id && !isNewLandlineContract) {
        const data = await getItem<LandlineContract>(
          READ_LANDLINECONTRACT_URL,
          id
        );
        if (data) {
          setLandlineContract(data);
          setEditedLandlineContract({
            ...data,
          });
        }
      }
    };

    fetchLandline();
  }, [id, isNewLandlineContract]);

  const handleSaveChanges = async () => {
    if (isNewLandlineContract) {
      await createItem<LandlineContract>(
        CREATE_LANDLINECONTRACT_URL,
        editedLandlineContract as LandlineContract
      );
    } else if (id) {
      await updateItem<LandlineContract>(
        UPDATE_LANDLINECONTRACT_URL,
        id,
        editedLandlineContract as LandlineContract
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedLandlineContract((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedLandlineContract[field.id as keyof LandlineContract], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedLandlineContract((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!landlineContract && !isNewLandlineContract) {
    return <div>Loading...</div>;
  }
  if (!landlineContract && !isNewLandlineContract) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewLandlineContract
            ? "Neuen Festnetz-Vertrag anlegen"
            : "Landlinedetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LandlineContractDetail;
