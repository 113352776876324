import axios from "axios";
import { toast } from "react-toastify";

const getItems = async <T,>(url: string): Promise<T | null> => {
  try {
    const response = await axios.get<T>(url);
    return response.data;
  } catch (error) {
    toast.error(`Fehler beim Abrufen der Daten von ${url}`);
    return null;
  }
};

export default getItems;
