import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { CustomerHardware } from "../../../models/CustomerHardware";
import {
  DELETE_CUSTOMERHARDWARE_URL,
  READ_CUSTOMERHARDWARES_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const CustomerHardwareOverview: React.FC = () => {
  const navigate = useNavigate();
  const [customerHardwares, setCustomerHardwares] = useState<
    CustomerHardware[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentCustomerHardwares = customerHardwares.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "customerHardware";

  useEffect(() => {
    const fetchCustomerHardwares = async () => {
      const data = await getItems<CustomerHardware[]>(
        READ_CUSTOMERHARDWARES_URL
      );
      if (data) {
        setCustomerHardwares(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchCustomerHardwares();
  }, []);

  const handleDelete = async (customerHardware: CustomerHardware) => {
    const deleted = await deleteItem(
      DELETE_CUSTOMERHARDWARE_URL,
      customerHardware.id!
    );
    if (deleted) {
      setCustomerHardwares((prevCustomerHardwares) =>
        prevCustomerHardwares.filter((comp) => comp.id !== customerHardware.id)
      );
      setTotalPages(Math.ceil(customerHardwares.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Kunden-Hardware</h1>

            <BasicButton
              buttonTitle={"Neue Kundenhardware anlegen"}
              onClick={() =>
                navigateToCreate(navigate, `/customerHardware/create`)
              }
            />
          </div>
          <div className="number">
            Alle Computer ({customerHardwares.length})
          </div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Kennung intern</th>
                  <th>Typ</th>
                  <th>Projektnummer</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentCustomerHardwares.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentCustomerHardwares.map((customerHardware) => (
                    <tr key={customerHardware.id}>
                      <td>{customerHardware.type}</td>
                      <td>{customerHardware.projectnumber}</td>
                      <td>{}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              customerHardware.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              customerHardware.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(customerHardware)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default CustomerHardwareOverview;
