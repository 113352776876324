import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Employee } from "../../../models/Employee";
import { Monitor } from "../../../models/Monitor";
import {
  CREATE_MONITOR_URL,
  READ_MONITOR_URL,
  UPDATE_MONITOR_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import sectionsData from "./monitorInputFields.json";

interface EditedMonitor extends Monitor {
  company: Employee.Company;
}

const MonitorDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [monitor, setMonitor] = useState<Monitor | null>(null);
  const [editedMonitor, setEditedMonitor] = useState<EditedMonitor>({
    aspectRatio: "",
    bill_number: "",
    company: Employee.Company.amanzii,
    curved: false,
    heightAdjustable: false,
    identifier: "",
    inventory_number: "",
    manufacturer: "",
    model: "",
    order_number: "",
    resolution: "",
    scrapped: false,
    serialnumber: "",
    status: false,
    type: Monitor.Type.Monitor,
    comment: "",
  });

  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewMonitor] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchMonitor = async () => {
      if (id && !isNewMonitor) {
        const data = await getItem<Monitor>(READ_MONITOR_URL, id);
        if (data) {
          setMonitor(data);
          setEditedMonitor({
            ...data,
          });
        }
      }
    };

    fetchMonitor();
  }, [id, isNewMonitor, location]);

  const handleSaveChanges = async () => {
    if (isNewMonitor) {
      await createItem<Monitor>(CREATE_MONITOR_URL, editedMonitor as Monitor);
    } else if (id) {
      await updateItem<Monitor>(
        UPDATE_MONITOR_URL,
        id,
        editedMonitor as Monitor
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedMonitor((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedMonitor[field.id as keyof Monitor], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedMonitor((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  const MonitorComponent = ({ monitor }: { monitor: Monitor }) => {
    return (
      <div>
        <p>Status: {status_to_words(monitor.status)}</p>
        <p>Scrapped: {scrapped_to_words(monitor.scrapped)}</p>
      </div>
    );
  };

  if (!monitor && !isNewMonitor) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewMonitor ? "Neuen Monitor anlegen" : "Monitordetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>
        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MonitorDetail;
