import { ReactNode } from "react";
import "../styles/Layout.css";
import Sidebar from "./sidebar/Sidebar";

interface LayoutProps {
  children: ReactNode;
}

const Layout = (props: LayoutProps) => {
  return (
    <div className="layout">
      <div className="content">
        <Sidebar />
        <main>{props.children}</main>
      </div>
    </div>
  );
};

export default Layout;
