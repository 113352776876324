import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Employee } from "../../../models/Employee";
import { NetworkDevice } from "../../../models/NetworkDevice";
import {
  CREATE_NETWORKDEVICE_URL,
  READ_NETWORKDEVICE_URL,
  UPDATE_NETWORKDEVICE_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./networkDeviceInputFields.json";

const NetworkDeviceDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [networkDevice, setNetworkDevice] = useState<NetworkDevice | null>(
    null
  );
  const [editedNetworkDevice, setEditedNetworkDevice] = useState<NetworkDevice>(
    {
      bill_number: "",
      category: NetworkDevice.Type.option_a,
      comment: "",
      company: Employee.Company.amanzii,
      firmware: "",
      inventory_number: 0,
      manufacturer: "",
      model: "",
      networkIp: "",
      networkMac: "",
      order_number: "",
      password: "",
      port_type: NetworkDevice.Type.option_a,
      purchaseDate: "",
      purchase_price: 0,
      scrapped: false,
      serialnumber: "",
      status: false,
      user: "",
      assignedEmployee: 0,
      classType: "",
      id: 0,
      identifier: "",
    }
  );
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewNetworkDevice] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchNetworkDevice = async () => {
      if (id && !isNewNetworkDevice) {
        const data = await getItem<NetworkDevice>(READ_NETWORKDEVICE_URL, id);
        if (data) {
          setNetworkDevice(data);
          setEditedNetworkDevice({
            ...data,
          });
        }
      }
    };

    fetchNetworkDevice();
  }, [id, isNewNetworkDevice]);

  const handleSaveChanges = async () => {
    if (isNewNetworkDevice) {
      await createItem<NetworkDevice>(
        CREATE_NETWORKDEVICE_URL,
        editedNetworkDevice
      );
    } else if (id) {
      await updateItem<NetworkDevice>(
        UPDATE_NETWORKDEVICE_URL,
        id,
        editedNetworkDevice
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedNetworkDevice((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedNetworkDevice[field.id as keyof NetworkDevice], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedNetworkDevice((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  const NetworkDeviceComponent = ({
    networkDevice,
  }: {
    networkDevice: NetworkDevice;
  }) => {
    return (
      <div>
        <p>Status: {status_to_words(networkDevice.status)}</p>
        <p>Scrapped: {scrapped_to_words(networkDevice.scrapped)}</p>
      </div>
    );
  };

  if (!networkDevice && !isNewNetworkDevice) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewNetworkDevice
            ? "Neues Netzwerkgerät anlegen"
            : "Netzwerkgerätedetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NetworkDeviceDetail;
