import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Accessory } from "../../../models/Accessory";
import { Employee } from "../../../models/Employee";
import {
  CREATE_ACCESSORY_URL,
  READ_ACCESSORY_URL,
  UPDATE_ACCESSORY_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./accessoryInputFields.json";

const AccessoryDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [accessory, setAccessory] = useState<Accessory | null>(null);
  const [editedAccessory, setEditedAccessory] = useState<Accessory>({
    assignedEmployee: 0,
    billNumber: 0,
    category: Accessory.Category.others,
    classType: "",
    comment: "",
    company: Employee.Company.amanzii,
    id: 0,
    identifier: "",
    inventory_number: 0,
    designation: "",
    model: "",
    orderNumber: "",
    purchaseDate: "",
    purchase_price: 0,
    scrapped: false,
    serialnumber: "",
    status: false,
  });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewAccessory] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchAccessory = async () => {
      if (id && !isNewAccessory) {
        const data = await getItem<Accessory>(READ_ACCESSORY_URL, id);
        if (data) {
          setAccessory(data);
          setEditedAccessory({
            ...data,
          });
        }
      }
    };

    fetchAccessory();
  }, [id, isNewAccessory]);

  const handleSaveChanges = async () => {
    if (isNewAccessory) {
      await createItem<Accessory>(CREATE_ACCESSORY_URL, editedAccessory);
    } else if (id) {
      await updateItem<Accessory>(UPDATE_ACCESSORY_URL, id, editedAccessory);
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedAccessory((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedAccessory[field.id as keyof Accessory], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedAccessory((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));
  const AccessoryComponent = ({ accessory }: { accessory: Accessory }) => {
    return (
      <div>
        <p>Status: {status_to_words(accessory.status)}</p>
        <p>Scrapped: {scrapped_to_words(accessory.scrapped)}</p>
      </div>
    );
  };

  if (!accessory && !isNewAccessory) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewAccessory
            ? "Neue Büroausstattung anlegen"
            : "Ausstattungsdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AccessoryDetail;
