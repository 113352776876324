import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { CustomerHardware } from "../../../models/CustomerHardware";
import {
  CREATE_CUSTOMERHARDWARE_URL,
  READ_CUSTOMERHARDWARE_URL,
  UPDATE_CUSTOMERHARDWARE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./customerHardwareInputFields.json";

const CustomerHardwareDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [customerHardware, setCustomerHardware] =
    useState<CustomerHardware | null>(null);
  const [editedCustomerHardware, setEditedCustomerHardware] =
    useState<CustomerHardware>({
      comment: undefined,
      identifier_extern: "",
      identifier_intern: "",
      issue_date: "",
      projectnumber: "",
      returnDate: "",
      assignedEmployee: 0,
      classType: "",
      id: 0,
      type: CustomerHardware.Type.option_a,
    });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewCustomerHardware] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchCustomerHardware = async () => {
      if (id && !isNewCustomerHardware) {
        const data = await getItem<CustomerHardware>(
          READ_CUSTOMERHARDWARE_URL,
          id
        );
        if (data) {
          setCustomerHardware(data);
          setEditedCustomerHardware({
            ...data,
          });
        }
      }
    };

    fetchCustomerHardware();
  }, [id, isNewCustomerHardware]);

  const handleSaveChanges = async () => {
    if (isNewCustomerHardware) {
      await createItem<CustomerHardware>(
        CREATE_CUSTOMERHARDWARE_URL,
        editedCustomerHardware
      );
    } else if (id) {
      await updateItem<CustomerHardware>(
        UPDATE_CUSTOMERHARDWARE_URL,
        id,
        editedCustomerHardware
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedCustomerHardware((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedCustomerHardware[field.id as keyof CustomerHardware], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedCustomerHardware((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!customerHardware && !isNewCustomerHardware) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewCustomerHardware
            ? "Neue Kunden-Hardware anlegen"
            : "Kunden-Hardwaredetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CustomerHardwareDetail;
