import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Literature } from "../../../models/Literature";
import {
  CREATE_LITERATURE_URL,
  READ_LITERATURE_URL,
  UPDATE_LITERATURE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./literatureInputFields.json";

const LiteratureDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [literature, setLiterature] = useState<Literature | null>(null);
  const [editedLiterature, setEditedLiterature] = useState<Literature>({
    assignedEmployee: 0,
    author: "",
    bill_number: 0,
    comment: "",
    id: 0,
    identifier: "",
    isbn: "",
    order_number: 0,
    purchaseDate: "",
    purchase_price: 0,
    title: "",
    type: Literature.Type.option_a,
  });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewLiterature] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchLiterature = async () => {
      if (id && !isNewLiterature) {
        const data = await getItem<Literature>(READ_LITERATURE_URL, id);
        if (data) {
          setLiterature(data);
          setEditedLiterature({
            ...data,
          });
        }
      }
    };

    fetchLiterature();
  }, [id, isNewLiterature]);

  const handleSaveChanges = async () => {
    if (isNewLiterature) {
      await createItem<Literature>(CREATE_LITERATURE_URL, editedLiterature);
    } else if (id) {
      await updateItem<Literature>(UPDATE_LITERATURE_URL, id, editedLiterature);
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedLiterature((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedLiterature[field.id as keyof Literature], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedLiterature((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!literature && !isNewLiterature) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewLiterature ? "Neue Literatur anlegen" : "Literaturdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LiteratureDetail;
