import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import BasicButton from "../buttons/BasicButton";
import { useStyle as useButtonStyle } from "../buttons/BasicButton.styles";
import { useStyle as useButtonGroupStyle } from "./OptionsButtonGroup.styles";

const OptionsButtonGroup = (props: OptionsButtonGroupProps) => {
  const buttonGroupStyle = useButtonGroupStyle();
  const buttonStyle = useButtonStyle();
  return (
    <div style={buttonGroupStyle.buttonGroup}>
      <BasicButton
        buttonIcon={<VisibilityIcon />}
        onClick={props.viewOnClick}
      />
      <BasicButton buttonIcon={<EditIcon />} onClick={props.editOnClick} />
      <BasicButton
        sx={buttonStyle.buttonDelete}
        buttonIcon={<DeleteIcon />}
        onClick={props.deleteOnClick}
      />
    </div>
  );
};

export default OptionsButtonGroup;
