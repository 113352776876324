import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Employee } from "../../../models/Employee";
import { Mobilephone } from "../../../models/Mobilephone";
import {
  CREATE_MOBILEPHONE_URL,
  READ_MOBILEPHONE_URL,
  UPDATE_MOBILEPHONE_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./mobilephoneInputFields.json";

const MobilephoneDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [mobilephone, setMobilephone] = useState<Mobilephone | null>(null);
  const [editedMobilephone, setEditedMobilephone] = useState<Mobilephone>({
    assignedEmployee: 0,
    bill_number: "",
    classType: "",
    comment: "",
    company: Employee.Company.amanzii,
    id: 0,
    identifier: "",
    imei1: "",
    imei2: "",
    inventory_number: "",
    manufacturer: "",
    model: "",
    order_number: "",
    purchase_price: "",
    purchase_date: "",
    scrapped: false,
    serialnumber: "",
    status: false,
  });

  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewMobilephone] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchMobilephone = async () => {
      if (id && !isNewMobilephone) {
        const data = await getItem<Mobilephone>(READ_MOBILEPHONE_URL, id);
        if (data) {
          setMobilephone(data);
          setEditedMobilephone({
            ...data,
          });
        }
      }
    };

    fetchMobilephone();
  }, [id, isNewMobilephone]);

  const handleSaveChanges = async () => {
    if (isNewMobilephone) {
      await createItem<Mobilephone>(CREATE_MOBILEPHONE_URL, editedMobilephone);
    } else if (id) {
      await updateItem<Mobilephone>(
        UPDATE_MOBILEPHONE_URL,
        id,
        editedMobilephone
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedMobilephone((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedMobilephone[field.id as keyof Mobilephone], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedMobilephone((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  const MobilephoneComponent = ({
    mobilephone,
  }: {
    mobilephone: Mobilephone;
  }) => {
    return (
      <div>
        <p>Status: {status_to_words(mobilephone.status)}</p>
        <p>Scrapped: {scrapped_to_words(mobilephone.scrapped)}</p>
      </div>
    );
  };

  if (!mobilephone && !isNewMobilephone) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewMobilephone ? "Neues Handy anlegen" : "Mobilephonedetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MobilephoneDetail;
