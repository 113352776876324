import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: '2fd7d532-96b5-466a-be26-c4815b5129b0',
        authority: 'https://login.microsoftonline.com/52cbaa8d-a4ca-43d1-871c-e51e81e53e43',
        redirectUri: 'https://web.qs1.ziings.amanzii-test.com',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};
