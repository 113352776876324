import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Contract } from "../../../models/Contract";
import {
  DELETE_CONTRACT_URL,
  READ_CONTRACTS_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const ContractOverview: React.FC = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentContracts = contracts.slice(indexOfFirstItem, indexOfLastItem);
  const allContracts = contracts.length;
  const resource = "contract";

  useEffect(() => {
    const fetchContracts = async () => {
      const data = await getItems<Contract[]>(READ_CONTRACTS_URL);
      if (data) {
        setContracts(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchContracts();
  }, []);

  const handleDelete = async (contract: Contract) => {
    const deleted = await deleteItem(DELETE_CONTRACT_URL, contract.id!);
    if (deleted) {
      setContracts((prevContracts) =>
        prevContracts.filter((comp) => comp.id !== contract.id)
      );
      setTotalPages(Math.ceil(contracts.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht SIM-Verträge</h1>
            <BasicButton
              buttonTitle={"Neuen SIM-Vertrag anlegen"}
              onClick={() => navigateToCreate(navigate, `/contract/create`)}
            />
          </div>
          <div className="number">Alle SIM-Verträge ({allContracts})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Handynummer</th>
                  <th>Provider</th>
                  <th>Netz</th>
                  <th>Firma</th>
                  <th>mtl. Kosten (exkl. Rabatt)</th>
                  <th>mtl. Kosten (inkl. Rabatt)</th>
                  <th>Zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentContracts.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentContracts.map((contract) => (
                    <tr>
                      <td>{contract.phoneNumber}</td>
                      <td>{contract.provider}</td>
                      <td>{contract.mobileNetwork}</td>
                      <td>{contract.company}</td>
                      <td>{contract.monthlyCost}</td>
                      <td>{contract.computedMonthlyCost}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              contract.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              contract.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(contract)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default ContractOverview;
