import React from "react";
import "../styles/FormComponent.css";

// Define the interface for a single input field
export interface InputField {
  label: string;
  placeholder?: string;
  id: string;
  type: any;
  required?: boolean;
  options?: string[];
  value: any;
  handleInputChange: (params: { id: string; value: any }) => void;
  disabled: boolean;
}

// Define the interface for a section containing multiple input fields
interface Section {
  sectionTitle: string;
  fields: InputField[];
}

// Component props include an array of sections and an editMode boolean
const FormComponent: React.FC<{
  sections: Section[];
  editMode: boolean;
}> = ({ sections, editMode }) => {
  return (
    <div className="form-container">
      {sections.map((section, index) => (
        <div key={index} className="form-section">
          <h2>{section.sectionTitle}</h2>
          <div className="form-row">
            {section.fields.map((input) => (
              <div key={input.id} className="form-group">
                <label htmlFor={input.id}>
                  {input.label}: {input.required ? "*" : ""}
                </label>
                {input.type === "select" ? (
                  <select
                    id={input.id}
                    value={(input.value as string) ?? input.options?.[0] ?? ""}
                    onChange={(e) =>
                      input.handleInputChange({
                        id: input.id,
                        value: e.target.value,
                      })
                    }
                    disabled={!editMode}
                  >
                    {input.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : input.type === "checkbox" ? (
                  <div className="slider-container">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id={input.id}
                        checked={!!input.value}
                        onChange={(e) =>
                          input.handleInputChange({
                            id: input.id,
                            value: e.target.checked,
                          })
                        }
                        disabled={!editMode}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                ) : (
                  <input
                    type={input.type}
                    id={input.id}
                    placeholder={input.placeholder}
                    value={input.value as string}
                    onChange={(e) =>
                      input.handleInputChange({
                        id: input.id,
                        value: e.target.value,
                      })
                    }
                    disabled={!editMode}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormComponent;
