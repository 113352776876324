import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Employee } from "../../../models/Employee";
import { License } from "../../../models/License";
import {
  CREATE_LICENSE_URL,
  READ_LICENSE_URL,
  UPDATE_LICENSE_URL,
} from "../../../services/apiRoutes";
import { status_to_words } from "../../../services/booleanToWords";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import sectionsData from "./licenseInputFields.json";

const LicenseDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [license, setLicense] = useState<License>();
  const [editedLicense, setEditedLicense] = useState<License>({
    bill_number: "",
    company: Employee.Company.amanzii,
    contractDuration: "",
    id: 0,
    inventory_number: "",
    licenseKey: "",
    licenseType: "",
    licensor: "",
    order_number: "",
    paymentCycle: License.paymentCycle.month,
    periodOfNotice: "",
    purchase_price: "",
    purchase_date: "",
    status: false,
  });
  const editMode = location.state?.editMode ?? false;
  const isNewLicense = location.pathname.includes("/create");

  useEffect(() => {
    const fetchLicense = async () => {
      if (id && !isNewLicense) {
        const data = await getItem<License>(READ_LICENSE_URL, id);
        if (data) {
          setLicense(data);
          setEditedLicense({
            ...data,
          });
        }
      }
    };

    fetchLicense();
  }, [id, isNewLicense]);

  const handleSaveChanges = async () => {
    if (isNewLicense) {
      await createItem<License>(CREATE_LICENSE_URL, editedLicense as License);
    } else if (id) {
      await updateItem<License>(
        UPDATE_LICENSE_URL,
        id,
        editedLicense as License
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedLicense((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedLicense[field.id as keyof License], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedLicense((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  const LicenseComponent = ({ license }: { license: License }) => {
    return (
      <div>
        <p>Status: {status_to_words(license.status)}</p>
      </div>
    );
  };

  if (!license && !isNewLicense) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewLicense ? "Neue Lizenz anlegen" : "Lizenzdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>
        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LicenseDetails;
