export const useStyle = () => {
  return {
    tabBar: {
      marginBottom: "20px",

      "& .MuiTabs-flexContainer": {
        gap: "20px",
      },

      "& .MuiTab-root": {
        textTransform: "none !important",
        color: "#001F26",
        fontSize: "20px",
        padding: 0,

        "&.Mui-selected": {
          color: "#001F26",
        },
      },

      "& .MuiTabs-indicator": {
        backgroundColor: "#cee7ee",
        margin: 0,
        padding: 0,
      },
    },
  };
};
