import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class Monitor extends Resource {
  identifier!: string;
  status!: boolean;
  scrapped!: boolean;
  bill_number?: string | null;
  order_number?: string | null;
  inventory_number?: string | null;
  manufacturer!: string;
  model!: string;
  serialnumber!: string;
  resolution!: string;
  size?: number | null;
  panel?: string | null;
  aspectRatio!: string;
  type!: Monitor.Type;
  company!: Employee.Company;
  heightAdjustable!: boolean;
  curved!: boolean;
  purchase_date?: string | null;
  comment?: string | null;
}

export namespace Monitor {
  export enum Type {
    Monitor = "Monitor",
    Beamer = "Beamer",
  }
}
