import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Room } from "../../../models/Room";
import { DELETE_ROOM_URL, READ_ROOMS_URL } from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const RoomOverview: React.FC = () => {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentRooms = rooms.slice(indexOfFirstItem, indexOfLastItem);
  const resource = "room";

  useEffect(() => {
    const fetchRooms = async () => {
      const data = await getItems<Room[]>(READ_ROOMS_URL);
      if (data) {
        setRooms(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchRooms();
  }, []);

  const handleDelete = async (room: Room) => {
    const deleted = await deleteItem(DELETE_ROOM_URL, room.id!);
    if (deleted) {
      setRooms((prevRooms) => prevRooms.filter((comp) => comp.id !== room.id));
      setTotalPages(Math.ceil(rooms.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Räume</h1>
            <BasicButton
              buttonTitle={"Neuen Raum anlegen"}
              onClick={() => navigateToCreate(navigate, `/room/create`)}
            />
          </div>
          <div className="number">Alle Räume ({rooms.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Kennung</th>
                  <th>Straße</th>
                  <th>Hausnummer</th>
                  <th>Anmerkung</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentRooms.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentRooms.map((room) => (
                    <tr key={room.id}>
                      <td>{room.identifier}</td>
                      <td>{room.street}</td>
                      <td>{room.city}</td>
                      <td>{room.comment}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(navigate, resource, room.id!!, false)
                          }
                          editOnClick={() =>
                            handleSiteView(navigate, resource, room.id!!, true)
                          }
                          deleteOnClick={() => handleDelete(room)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default RoomOverview;
