import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Monitor } from "../../../models/Monitor";
import {
  DELETE_MONITOR_URL,
  READ_MONITORS_URL,
} from "../../../services/apiRoutes";
import {
  scrapped_to_words,
  status_to_words,
} from "../../../services/booleanToWords";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 4;

const MonitorOverview: React.FC = () => {
  const navigate = useNavigate();
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [, setSelectedMonitor] = useState<Monitor | null>(null);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentMonitors = monitors.slice(indexOfFirstItem, indexOfLastItem);
  const resource = "monitor";

  useEffect(() => {
    const fetchMonitors = async () => {
      const data = await getItems<Monitor[]>(READ_MONITORS_URL);
      if (data) {
        setMonitors(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchMonitors();
  }, []);

  const handleDelete = async (monitor: Monitor) => {
    const deleted = await deleteItem(DELETE_MONITOR_URL, monitor.id!);
    if (deleted) {
      setMonitors((prevMonitors) =>
        prevMonitors.filter((mon) => mon.id !== monitor.id)
      );
      setTotalPages(Math.ceil(monitors.length / ITEMS_PER_PAGE));
    }
  };

  const handleResourceClick = (monitor: Monitor) => {
    setSelectedMonitor(monitor);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Monitore</h1>
            <BasicButton
              buttonTitle={"Neuen Monitor anlegen"}
              onClick={() => navigateToCreate(navigate, `/monitor/create`)}
            />
          </div>
          <div className="number">Alle Monitore ({monitors.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Modell</th>
                  <th>Hersteller</th>
                  <th>Auflösung</th>
                  <th>Seitenverhältnis</th>
                  <th>Größe</th>
                  <th>Panel</th>
                  <th>Unternehmen</th>
                  <th>Status</th>
                  <th>Verschrottet</th>
                  <th>Zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentMonitors.map((monitor) => (
                  <tr key={monitor.id}>
                    <td>{monitor.model}</td>
                    <td>{monitor.manufacturer}</td>
                    <td>{monitor.resolution}</td>
                    <td>{monitor.aspectRatio}</td>
                    <td>{monitor.size}</td>
                    <td>{monitor.panel}</td>
                    <td>{monitor.company}</td>
                    <td>{status_to_words(monitor.status)}</td>
                    <td>{scrapped_to_words(monitor.scrapped)}</td>
                    <td></td>
                    <td></td>
                    <td>
                      <OptionsButtonGroup
                        viewOnClick={() =>
                          handleSiteView(
                            navigate,
                            resource,
                            monitor.id!!,
                            false
                          )
                        }
                        editOnClick={() =>
                          handleSiteView(navigate, resource, monitor.id!!, true)
                        }
                        deleteOnClick={() => handleDelete(monitor)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default MonitorOverview;
