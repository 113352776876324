import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Compartment } from "../../../models/Compartment";
import {
  CREATE_COMPARTMENT_URL,
  READ_COMPARTMENT_URL,
  UPDATE_COMPARTMENT_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./compartmentInputFields.json";

const CompartmentDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [compartment, setCompartment] = useState<Compartment | null>(null);
  const [editedCompartment, setEditedCompartment] = useState<Compartment>({
    assignedEmployee: 0,
    classType: "",
    id: 0,
    identifier: "",
  });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [isNewCompartment] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchCompartment = async () => {
      if (id && !isNewCompartment) {
        const data = await getItem<Compartment>(READ_COMPARTMENT_URL, id);
        if (data) {
          setCompartment(data);
          setEditedCompartment({
            ...data,
          });
        }
      }
    };

    fetchCompartment();
  }, [id, isNewCompartment]);

  const handleSaveChanges = async () => {
    if (isNewCompartment) {
      await createItem<Compartment>(CREATE_COMPARTMENT_URL, editedCompartment);
    } else if (id) {
      await updateItem<Compartment>(
        UPDATE_COMPARTMENT_URL,
        id,
        editedCompartment
      );
    }
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedCompartment((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedCompartment[field.id as keyof Compartment], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedCompartment((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!compartment && !isNewCompartment) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewCompartment ? "Neues Fach anlegen" : "Fachdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CompartmentDetail;
