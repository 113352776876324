export const useStyle = () => {
  return {
    button: {
      fontFamily: "-apple-system, BlinkMacSystemFont",
      fontSize: "16px",
      textTransform: "none",
      padding: "10px 20px",
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#40a9bf !important",
      color: "#fff",
      cursor: "pointer",
      position: "inherit",
      transition: "transform 0.2s ease-in-out",
      "&:hover": {
        filter: "brightness(90%)",
      },
    },
    buttonDelete: {
      backgroundColor: "#BE543C !important",
    },
  };
};
