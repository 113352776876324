import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FormComponent from "../../../components/FormComponent";
import Layout from "../../../components/Layout";
import BasicButton from "../../../components/buttons/BasicButton";
import { Contract } from "../../../models/Contract";
import { Employee } from "../../../models/Employee";
import {
  CREATE_CONTRACT_URL,
  READ_CONTRACT_URL,
  UPDATE_CONTRACT_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import getItem from "../../../services/getItem";
import updateItem from "../../../services/updateItem";
import "../../../styles/Details.css";
import "../../../styles/FormComponent.css";
import sectionsData from "./contractInputFields.json";

interface EditedContract extends Contract {
  company: Employee.Company;
}

const ContractDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [contract, setContract] = useState<Contract | null>(null);
  const [editedContract, setEditedContract] = useState<EditedContract>({
    comment: "",
    company: Employee.Company.amanzii,
    computedMonthlyCost: 0,
    contractDuration: "",
    contractEnd: "",
    contractMail: "",
    contractNumber: "",
    contractStart: "",
    customerNumber: "",
    dataVolume: 0,
    dataVolumeUnit: Contract.CapacityUnit.GB,
    discountDuration: "",
    hotline: "",
    hotlinePassword: "",
    minimumContractDuration: "",
    mobileNetwork: "",
    monthlyCost: 0,
    monthlyDiscount: 0,
    password: "",
    periodOfNotice: "",
    phoneNumber: 0,
    pin: 0,
    provider: "",
    puk: 0,
    sim: 0,
    username: "",
  });
  const [editMode] = useState<boolean>(location.state?.editMode || false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isNewContract] = useState<boolean>(
    location.pathname.includes("/create")
  );

  useEffect(() => {
    const fetchContract = async () => {
      if (id && !isNewContract) {
        const data = await getItem<Contract>(READ_CONTRACT_URL, id);
        if (data) {
          setContract(data);
          setEditedContract({
            ...data,
          });
        }
      }
    };

    fetchContract();
  }, [id, isNewContract]);

  const handleSaveChanges = async () => {
    if (isNewContract) {
      await createItem<Contract>(
        CREATE_CONTRACT_URL,
        editedContract as Contract
      );
    } else if (id) {
      await updateItem<Contract>(
        UPDATE_CONTRACT_URL,
        id,
        editedContract as Contract
      );
    }

    setShowConfirmation(true);
  };

  const handleInputChange = (id: string, value: string | number | boolean) => {
    setEditedContract((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedContract[field.id as keyof Contract], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        setEditedContract((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      },
      disabled: !editMode,
    })),
  }));

  if (!contract && !isNewContract) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="details-container">
        <h1 className="details-heading">
          {isNewContract ? "Neuen SIM-Vertrag anlegen" : "Contractdetails"}
        </h1>
        <FormComponent sections={sections} editMode={editMode}></FormComponent>

        <div className="actions">
          {editMode && (
            <BasicButton
              buttonTitle="Speichern"
              onClick={handleSaveChanges}
            ></BasicButton>
          )}
        </div>

        {showConfirmation && (
          <div className="confirmation-modal">
            <p>Änderungen erfolgreich gespeichert!</p>
            <button type="submit" onClick={() => setShowConfirmation(false)}>
              Schließen
            </button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContractDetail;
