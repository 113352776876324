import { format } from "date-fns";
import { de } from "date-fns/locale";

export const formatCellContent = (content: any): string => {
  if (content === null || content === undefined) return "";
  if (Array.isArray(content)) {
    return content.join(", ");
  }
  if (content instanceof Date) {
    return format(content, "dd.MM.yyyy", { locale: de });
  }
  return String(content);
};
