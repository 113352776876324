export const useStyle = () => {
  return {
    page: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    content: {
      display: "flex",
      gap: "16px",
    },

    logo: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },

    logo_img: {
      width: "32px",
    },

    logo_name: {
      color: "#40A9BF",
      fontWeight: "500",
      margin: 0,
      padding: 0,
    },

    divider: {
      height: "revert" /* revert weird mui styles */,
      backgroundColor: "#CEE7EE",
    },
  };
};
