export class Employee {
  id!: number;
  lastName: string = "";
  firstName: string = "";
  company!: Employee.Company;
  department!: Employee.Department;
  workplace!: Employee.Workplace;
  role!: Employee.Role;
  employmentDate!: string;
  status!: boolean;
  leaveDate?: string | null;
}

export namespace Employee {
  export enum Company {
    Moxco = "MoXco",
    Vizavy = "Vizavy",
    amanzii = "amanzii",
  }

  export enum Department {
    Development = "Entwicklung",
    Management = "Verwaltung",
    Finance = "Finanzen",
  }

  export enum Workplace {
    Remote = "Remote",
    Braunschweig = "Braunschweig",
    Frankfurt = "Frankfurt",
  }

  export enum Role {
    Developer = "Entwickler",
    Tester = "Tester",
    Other = "Sonstiges",
  }
}
