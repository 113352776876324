import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Paginator";
import BasicButton from "../../../components/buttons/BasicButton";
import OptionsButtonGroup from "../../../components/options-button-group/OptionsButtonGroup";
import { Literature } from "../../../models/Literature";
import {
  DELETE_LITERATURE_URL,
  READ_LITERATURES_URL,
} from "../../../services/apiRoutes";
import deleteItem from "../../../services/deleteItem";
import getItems from "../../../services/getItems";
import {
  handleSiteView,
  navigateToCreate,
} from "../../../services/overviewUtils";
import "../../../styles/Overview.css";

const ITEMS_PER_PAGE = 5;

const LiteratureOverview: React.FC = () => {
  const navigate = useNavigate();
  const [literatures, setLiteratures] = useState<Literature[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentLiteratures = literatures.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const resource = "literature";

  useEffect(() => {
    const fetchLiteratures = async () => {
      const data = await getItems<Literature[]>(READ_LITERATURES_URL);
      if (data) {
        setLiteratures(data);
        setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
      }
    };
    fetchLiteratures();
  }, []);

  const handleDelete = async (literature: Literature) => {
    const deleted = await deleteItem(DELETE_LITERATURE_URL, literature.id!);
    if (deleted) {
      setLiteratures((prevLiteratures) =>
        prevLiteratures.filter((comp) => comp.id !== literature.id)
      );
      setTotalPages(Math.ceil(literatures.length / ITEMS_PER_PAGE));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <div className="overview">
        <div className="container">
          <div className="flex flex-between align-items-center">
            <h1>Übersicht Literatur</h1>
            <BasicButton
              buttonTitle={"Neue Literatur anlegen"}
              onClick={() => navigateToCreate(navigate, `/literature/create`)}
            />
          </div>
          <div className="number">Alle Literatur ({literatures.length})</div>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>Kennung</th>
                  <th>Typ</th>
                  <th>Titel</th>
                  <th>Autor</th>
                  <th>zugewiesener Mitarbeiter</th>
                  <th>Optionen</th>
                </tr>
              </thead>
              <tbody>
                {currentLiteratures.length === 0 ? (
                  <tr>
                    <td colSpan={5}>Keine Daten vorhanden</td>
                  </tr>
                ) : (
                  currentLiteratures.map((literature) => (
                    <tr key={literature.id}>
                      <td>{literature.identifier}</td>
                      <td>{literature.type}</td>
                      <td>{literature.title}</td>
                      <td>{literature.author}</td>
                      <td>{}</td>
                      <td>
                        <OptionsButtonGroup
                          viewOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              literature.id!!,
                              false
                            )
                          }
                          editOnClick={() =>
                            handleSiteView(
                              navigate,
                              resource,
                              literature.id!!,
                              true
                            )
                          }
                          deleteOnClick={() => handleDelete(literature)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default LiteratureOverview;
