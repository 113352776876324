import { Employee } from "./Employee";
import { Resource } from "./Resource";

export class Dooraccess extends Resource {
    door!: any;
    identifier!: string;
    status!: boolean; 
    scrapped!: boolean;
    comment?: string | null;
    company!: Employee.Company;
    serialnumber?: string | null;
    type!: Dooraccess.Type;
}

export namespace Dooraccess {
    export enum Type {
      mechanisch = "Mechanisch",
      elektronisch = "Elektronisch",
    }
}