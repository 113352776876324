import { NavigateFunction } from "react-router-dom";

export const navigateToCreate = (navigate: any, createUrl: string) => {
  navigate(createUrl, { state: { editMode: true } });
};

export const handleSiteView = (
  navigate: NavigateFunction,
  ressource: string,
  id: number,
  isEditMode: boolean
) => {
  const site = isEditMode ? "edit" : "view";
  navigate(`/${ressource}/${id}`, {
    state: { editMode: isEditMode },
  });
};
