import { Resource } from "./Resource";

export class CustomerHardware extends Resource {
  identifier_intern!: string;
  identifier_extern!: string;
  issue_date?: string;
  returnDate?: string;
  projectnumber!: string;
  type!: CustomerHardware.Type;
  comment?: string | null;
}

export namespace CustomerHardware {
  export enum Type {
    option_a = "Platzhalter a",
    option_b = "Platzhalter b",
  }
}
